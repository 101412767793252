import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPreview from "../components/blogPreview"

const IndexPage = ({ data }) => {
  const { allGraphCmsBlogPost } = data
  return (
    <Layout>
      <SEO title="Home" />
      <div className="space-y-4">
        <div className="w-full border-gray-800 rounded border-4">
          <div className="prose prose-black max-w-7xl relative z-10 px-4 py-12">
            <h1>Hi, I’m <span className="text-blue-600">Lars Hansen</span>. Welcome to my blog.</h1>
            <p className="text-gray-800 text-lg">
              I have always had a passionate interest in spirituality and
              religion. Specific reading, post-graduate study and employment
              have facilitated a journey and insights which might interest
              others.
            </p>
          </div>
        </div>
        <h2 className="text-xl md:text-3xl font-bold text-gray-800">
          Latest Posts
        </h2>
        <div className="grid md:grid-cols-2 gap-4 pb-4">
          {allGraphCmsBlogPost.nodes
            .slice(0, 2)
            .map(({ title, publishDate, body: { text } }) => (
              <BlogPreview title={title} publishDate={publishDate} excerpt={text.slice(0, 100) + "..."} />
            ))}
        </div>
        <h2 className="text-xl md:text-3xl font-bold text-gray-800">
          All Posts
        </h2>
        <div className="grid md:grid-cols-3 gap-4">
          {allGraphCmsBlogPost.nodes
            .slice(2)
            .map(({ title, publishDate, body: { text } }) => (
              <BlogPreview title={title} publishDate={publishDate} excerpt={text.slice(0, 100) + "..."} />
            ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allGraphCmsBlogPost(sort: { fields: publishDate, order: DESC }) {
      nodes {
        title
        publishDate
        body {
          text
        }
      }
    }
  }
`

export default IndexPage
