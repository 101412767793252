import { Link } from "gatsby"
import React from "react"
import slugify from "slugify"
import format from "date-fns/format"

const BlogPreview = ({ title, excerpt, publishDate }) => {
  return (
    <Link to={`/${slugify(title)}`}>
      <div className="w-full bg-gray-800 hover:bg-gray-700 text-white p-4 h-full rounded space-y-1">
        <h2 className="font-bold text-2xl">{title}</h2>
        <div className="rounded-full bg-gray-600 inline-block px-2">
            {publishDate && <label>{format(new Date(publishDate), "yyyy-MM-dd")}</label>}
        </div>
        <p className="text-lg">{excerpt}</p>
      </div>
    </Link>
  )
}

export default BlogPreview
